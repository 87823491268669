import React from "react";
import SEO from "../shared/seo";
import { useStaticQuery, graphql } from "gatsby";
import { ReferenciaContenido } from "../interfaces/ReferenciaContenido";
import LibraryComponents from "../components/templates/library-components/libraryComponents";
import Button from "../components/atoms/button/button";

const ContactPage = () => {

  const data = useStaticQuery(graphql`
    query LINKTREE {
      allContentfulSubcategorias(filter: {titulo: {eq: "Linktree"}}) {
        edges {
          node {
            nombreSubcategoria
            tipoContenido
            slug
            link
            tipoCampo
            referenciaInterna {
              nombreCategoria
              tipoPlantilla
              noCanonical
              noIndex
              esconderLoggin
              referenciaCabecera {
                tituloSpan
                tituloPrincipal
                migaDePan
                imagenHeader {
                  file {
                    url
                    fileName
                  }
                }
                tipoCabecera
              }
              referenciaContenido {
                tipoComponente
                evento
                tituloBoton
                redireccionBoton
                referenciaTabsContenido {
                  nombreTab
                  redireccionTab
                  imagenLibro {
                    file {
                      url
                      fileName
                    }
                  }
                }
                tituloGeneral
                referenciaPlantillaContenido {
                  descripcion {
                    json
                    content {
                      content {
                        value
                        content {
                          value
                        }
                      }
                    }
                  }
                  redireccionBoton
                  imagenPrincipal {
                    file {
                      url
                      fileName
                    }
                  }
                  tituloBanner
                }
                imagenPrincipal {
                  file {
                    url
                    fileName
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  return (
    <>
      <SEO title={data.allContentfulSubcategorias.edges[0].node.metaTitulo || "Recomendados "} 
        description={data.allContentfulSubcategorias.edges[0].node.metaDescripcion && data.allContentfulSubcategorias.edges[0].node.metaDescripcion.json.content[0].content[0].value }
        noIndex={data.allContentfulSubcategorias.edges[0].node.referenciaInterna.noIndex}
        noCanonical={data.allContentfulSubcategorias.edges[0].node.referenciaInterna.noCanonical}
      />
      { data.allContentfulSubcategorias.edges[0].node.referenciaInterna.referenciaContenido ? data.allContentfulSubcategorias.edges[0].node.referenciaInterna.referenciaContenido.map(
          (value: ReferenciaContenido, index: number) => (
            <LibraryComponents
              key={index}
              content={value}
              categoryName={data.allContentfulSubcategorias.edges[0].node.referenciaInterna.nombreCategoria}
            />
          )
      )
      :
        <></>
      }
      {
        data.allContentfulSubcategorias.edges[0].node.link ?
        <div className="o-linktree__final-btn">
          <Button
            type="link"
            href={data.allContentfulSubcategorias.edges[0].node.link}
            text={data.allContentfulSubcategorias.edges[0].node.tipoCampo || "VISITAR COMFAMA.COM"}
            isDisabled={false}
            classname="o-linktree__final-btn__btn"
          />
        </div>
        :
          null
      }
    </>
  );
};

export default ContactPage;
